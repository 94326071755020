import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const FusionFest2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/17.webp`;
    const p18 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/18.webp`;
    const p19 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/19.webp`;
    const p20 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/20.webp`;
    const p21 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/21.webp`;
    const p22 = `${config.base_image_url}/home/events-activities/school-programs/Fusionfest/22.webp`;
    
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
        },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },
        {
            src: p19,
            source: p19,
            rows: 1,
            cols: 1,
        },
        {
            src: p20,
            source: p20,
            rows: 1,
            cols: 1,
        },
        {
            src: p21,
            source: p21,
            rows: 1,
            cols: 1,
        },
        {
            src: p22,
            source: p22,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Annual Event – Fusion Fest 2025
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: PRE-PRIMARY         Date: 28th February 2025
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    FUSION FEST 2025: A Colourful Celebration of Creativity and Expression


                                    <br />
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    <strong>
                                        "Childhood is a canvas of imagination, painted with laughter, dreams, and the freedom to express. Fusion Fest 2025 was a celebration of this vibrant spirit, where every performance echoed the magic of innocence and boundless creativity."
                                    </strong>
                                    <br></br>
                                    Fusion Fest 2025 was a spectacular event that brought the stage to life with the vibrant performances of
                                    our Pre-Primary students. Our young learners captivated the audience with their charm, confidence and
                                    joy, creating an unforgettable experience and enthralled their proud parents and esteemed dignitaries.
                                    <br></br>
                                    The stage at Fusion Fest was transformed into a visual marvel as our students from Kindergarten brought
                                    various themes to life with creativity and energy. The Landforms segment showcased nature’s beauty
                                    through expressive narration and beautifully choreographed dances, both entertaining and educating the
                                    audience. The Rhyme land performance featured the tiniest stars, their sweet voices singing popular
                                    rhymes, followed by an energetic dance that filled the auditorium with joy. The Jungle theme blended a
                                    meaningful skit with a lesson on nature and kindness, concluding with a mesmerizing dance. The Cartoons
                                    segment was a lively act where children portrayed emotions through beloved cartoon characters,
                                    reminding everyone of the power of positivity, while a colourful ramp walk added charm. The event
                                    concluded with a breath-taking performance on Seasons, where children narrated and danced to represent
                                    the changing seasons, perfectly wrapping up the spectacular Kindergarten performances.
                                    <br></br>
                                    The Fusion Fest during the Montessori event showcased creativity and knowledge as students blended
                                    timeless traditions with modern concepts. The event began with the Fusion of Colours, where children
                                    demonstrated how mixing colours creates new shades, emphasising Montessori&#39;s hands-on learning
                                    approach, followed by an energetic dance performance. The Dance of Time then took the audience through
                                    the evolution of dance, from the graceful storytelling of classical Indian dance to the lively twists of Western
                                    styles, with high-energy Rock n Roll and Freestyle performances, celebrating the joy of movement. The
                                    grand finale was a splendid spectacle. From Home Chores to Modern Fitness, students explored how
                                    everyday activities like sweeping and cooking promoted a healthy lifestyle, contrasting them with today’s
                                    fitness routines like aerobics and gym workouts. Through a lively skit and fitness dance, they highlighted the
                                    importance of physical activity for both physical and mental well-being. This journey from Classic to
                                    Contemporary beautifully reflected how Montessori encourages children to explore, adapt and thrive across
                                    time.
                                    <br></br>
                                    With the guidance of their teachers, cooperation of their parents and the incredible support from the
                                    school, our students gave performances that left the audience mesmerised. Their confidence, enthusiasm,
                                    and innocence lit up the stage, making the event a truly unforgettable experience. The dignitaries and
                                    parents applauded the efforts of the young performers, appreciating the dedication and teamwork behind
                                    each act. Fusion Fest 2025 was more than just a stage performance; it was a celebration of learning,
                                    creativity and self-expression.
                                    <br></br>
                                    <div> As the curtains closed on this grand event, the echoes of laughter, applause, and cheers lingered, reminding
                                        us all of the magic that children bring into our lives. Here’s to many more such wonderful celebrations of
                                        talent, joy, and boundless potential!</div>
                                    <div><strong>"May the lessons of today, rooted in creativity, kindness and teamwork, grow into a future where every
                                        child’s potential is nurtured and celebrated"</strong></div>
                                    <br></br>


                                </Typography>

                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                <div> Laughter, applause, and endless smiles—Fusion Fest
                                    was a magical journey of creativity and expression!</div>

                                <br />

                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    <div>The stage was set, the talent was real, and the memories

                                        are forever!</div>
                                    <br />

                                </Typography>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default FusionFest2024;